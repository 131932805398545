import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/web.dom-collections.iterator.js";
let requireCtx;
try {
  requireCtx = require.context('./icons/', false,
  // 不解析子文件夹
  /\.svg$/);
} catch (err) {
  if ( /* 允许文件夹缺失（处理 git 无法提交空文件夹的情况） */
  err.code === 'MODULE_NOT_FOUND') {
    requireCtx = () => {};
    requireCtx.keys = () => [];
  } else {
    throw err;
  }
}
const fileNames = requireCtx.keys();
const names = Object.freeze(fileNames.map(fileName => fileName.split(/[/\\]+/).pop().replace(/\.\w+$/, '')));
fileNames.forEach(fileName => requireCtx(fileName));
export { names };
export default {
  name: 'SvgIcon',
  props: {
    icon: {
      type: String,
      required: true
    }
  }
};
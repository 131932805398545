// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/.store/css-loader@3.6.0/node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".src-views-404__box__38iMP{margin:2em 1.5em;color:#999;text-align:center;font-size:1.3em}", ""]);
// Exports
exports.locals = {
	"box": "src-views-404__box__38iMP"
};
module.exports = exports;

var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('i', {
    class: ['svg-icon', "svg-icon-".concat(_vm.icon)]
  }, [_c('svg', {
    staticClass: "svg-icon__icon"
  }, [_c('use', {
    attrs: {
      "xlink:href": "#svgSpriteIcon__".concat(_vm.icon)
    }
  })])]);
};
var staticRenderFns = [];
export { render, staticRenderFns };